import {Button} from '@mui/material';
import React from 'react'
import {SaveButtonProps} from '../../type';

const SaveButton:React.FC<SaveButtonProps> = ({ onClick }) => {
  return (
    <>
      <Button
        variant="outlined"
        sx={{
            backgroundColor: "var(--black)",
            borderColor: "var(--black)",
            color: "var(--white)",
            fontSize: "0.8rem",
            textTransform: "Capitalize",
            borderRadius: 30,
            "&:active": {
            borderColor: "black",
            },
            "&:hover": {
            borderColor: "var(--black)",
            color: "var(--white)",
            backgroundColor: "var( --gray-700)",
            },
        }}
        type="submit"
        onClick={onClick}
      >
        Salva Modifiche
      </Button>
    </>
  )
}

export default SaveButton;