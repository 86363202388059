import React from "react";
import Grid from "@mui/material/Grid";
import { Button, Container, Stack } from "@mui/material";
import Image from "react-bootstrap/Image";
import svg from "../../assets/404-error.svg";
import Typography from "@mui/material/Typography";

interface NotFoundComponentProps {
    onClickButton: () => void;
    titleButton: string;
    description: string;
    title: string;
    noButton?: boolean;
}

const NotFoundComponent: React.FC<NotFoundComponentProps> = ({ onClickButton, titleButton, description, title, noButton }) => {

  return (
    <Container style={{ alignItems: "center", justifyContent: "center", display: "flex", height: "80vh" }} maxWidth={false}>
      <Grid container spacing={2} justifyContent={"center"} alignItems={"center"} alignContent={"center"} width={"100%"}>
        <Grid item xs={10}>
          <Stack spacing={1} justifyContent="center" alignItems="center">
            <Image src={svg} style={{ maxWidth: 400, maxHeight: 400 }} />
            <Typography variant={"h4"}>{title}</Typography>
            <Typography variant={"subtitle2"} color={"#A9A9A9"} style={{ marginBottom: "10px" }}>
              {description}
            </Typography>
            {!noButton && (
                <Button variant={"contained"} onClick={onClickButton} sx={{ textTransform: "Capitalize" }}>
                {titleButton}
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFoundComponent;
