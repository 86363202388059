import {useTranslation} from "react-i18next";
import passwordInitialValues from "../../../formModels/password/passwordInitialValues";
import React, {useState} from "react";
import passwordSchema from "../../../formModels/password/passwordSchema";
import {RegistryApiService} from "../../../../../services/RegistryApiService";
import {IRegistryChangePasswordInitialValues} from "../../../../../types/registry";
import {
	Alert,
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Grid,
	IconButton,
	InputAdornment,
	TextField
} from "@mui/material";
import {Form, Formik, FormikHelpers, FormikTouched} from "formik";
import {API_AUTH_CHANGE_PASSWORD} from "../../../../../api/api";
import axios from "axios";
import {EAuthSessionData} from "../../../../../types/auth";
import {AuthApiService} from "../../../../../services/AuthApiService";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {IAlert} from "../../../../../types/commons";

const ChangePassword = () => {
	const {t} = useTranslation();
	const initialValues = passwordInitialValues;
	const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
	const schema = passwordSchema;
	const [mostraPwd, setMostraPwd] = useState(false);

	const [alert, setAlert] = useState<IAlert>({
		show: false,
		severity: "error",
		message: "",
		dismissible: true,
		onClose: () => {
			setAlert({...alert, show: false});
		},
	});

	const registryApiService = new RegistryApiService();
	const authApiService = new AuthApiService();

	const onSubmit = async (values: IRegistryChangePasswordInitialValues, {resetForm}: FormikHelpers<any>) => {
		const aToken = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN);
		try {
			setIsLoadingSubmit(true);
			const response = await authApiService.changePassword(values.newPassword);
			if (response) {
                setAlert({...alert, show: true, severity: "success", message: t("OPERATION_SUCCESS")});
                resetForm();
			} else {
				setAlert({...alert, show: true, severity: "error", message: t("OPERATION_ERROR")});
			}
		} catch (error: any) {
			setAlert({...alert, show: true, severity: "error", message: error.response.data.message});
		} finally {
			setIsLoadingSubmit(false);
		}
	};

	return (
		<Card variant="outlined">
			<CardHeader title={t("SETTINGS.TAB_PROFILE.CARD_PASSWORD.TITLE")}></CardHeader>
			<CardContent>
				<Formik<IRegistryChangePasswordInitialValues> initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
					{({handleSubmit, values, isValid, touched, errors, handleChange, handleBlur}) => {
						const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<IRegistryChangePasswordInitialValues>]);
						const isSubmitDisabled = !formIsTouched || !isValid;
						return (
							<Form onSubmit={handleSubmit}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12}>
										{alert.show && (
											<Alert className="mb-3" severity={alert.severity} onClose={alert.onClose}>
												{t(alert.message)}
											</Alert>
										)}
									</Grid>
									<Grid item xs={12}>
										<TextField
											name="newPassword"
											value={values.newPassword}
											onChange={handleChange}
											onBlur={handleBlur}
											inputProps={{maxLength: 16}}
											label="Password"
											error={touched.newPassword && !!errors.newPassword}
											helperText={touched.newPassword && errors.newPassword ? t(errors.newPassword) : ""}
											required
											fullWidth
											type={mostraPwd ? "text" : "password"}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton onClick={() => setMostraPwd(!mostraPwd)}>{mostraPwd ? <VisibilityOff /> : <Visibility />}</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											name="confirmPassword"
											value={values.confirmPassword}
											onChange={handleChange}
											onBlur={handleBlur}
											inputProps={{maxLength: 16}}
											label={t("SETTINGS.TAB_PROFILE.CARD_PASSWORD.CONFIRM_PASSWORD")}
											error={touched.confirmPassword && !!errors.confirmPassword}
											helperText={touched.confirmPassword && errors.confirmPassword ? t(errors.confirmPassword) : ""}
											required
											fullWidth
											type={mostraPwd ? "text" : "password"}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton onClick={() => setMostraPwd(!mostraPwd)}>{mostraPwd ? <VisibilityOff /> : <Visibility />}</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<Button
											type="submit"
											variant="contained"
											disableElevation
											color="secondary"
											disabled={isLoadingSubmit || isSubmitDisabled}
											size="large"
											startIcon={isLoadingSubmit ? <CircularProgress color="secondary" size={15} /> : null}
										>
											{t("SETTINGS.TAB_PROFILE.CARD_PROFILE.SUBMIT")}
										</Button>
									</Grid>
								</Grid>
							</Form>
						);
					}}
				</Formik>
			</CardContent>
		</Card>
	);
};

export default ChangePassword;
