import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import "../../colors.css";
import Image from "react-bootstrap/Image";
import svg from "../../assets/tick.png";
import {t} from "i18next";

export default function ConfirmEmail() {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				minHeight: "100vh",
			}}
		>
			<Image src={svg} style={{ maxWidth: 200, maxHeight: 200, marginBottom: 20 }} />
			<Typography variant="h2" style={{color: "var(--black)"}}>
				{t("THANKS")}
			</Typography>
			<Typography variant="subtitle2">
				{t("THANKS_DESCRIPTION")}
			</Typography>
			{/* <Link to="/login">Torna al Login</Link> */}
		</Box>
	);
}
