import {Box, Button, Card, CardContent, CardHeader, Chip, Grid, Skeleton, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PaymentApiService} from "../../../../../services/PaymentApiService";
import {IPlan} from "../../../../../types/payment";
import {formatStripePrice} from "../../../../../utils/helperFunctions";

const PlanCard = () => {
    const {t} = useTranslation();
    const paymentApiService = new PaymentApiService();
    const [billingPortalLinkLoading, setBillingPortalLinkLoading] = useState<boolean>(true);
    const [billingPortalLink, setBillingPortalLink] = useState<string | null>(null);
    const [currentPlan, setCurrentPlan] = useState<IPlan | null>(null);
    const [currentPlanLoading, setCurrentPlanLoading] = useState<boolean>(true);

    const buttonEditSubscription = () => {
        return (
            <>
                {billingPortalLinkLoading && <Skeleton width={150} height={50}/>}
                {!billingPortalLinkLoading && <Button type="submit" variant="contained"
                                                      disableElevation
                                                      color="secondary"
                                                      onClick={onClickEditSubscription}
                                                      sx={{minWidth: '150px'}}
                                                      disabled={!billingPortalLink && !billingPortalLinkLoading}>
                    {t('SETTINGS.TAB_BILLING.CARD_PLAN.BILLING_PORTAL_LINK')}
                </Button>}
            </>
        )
    }

    const getEditSubscriptionLink = () => {
        paymentApiService.billingPortalLink().then((link) => {
            setBillingPortalLinkLoading(false)
            setBillingPortalLink(link);
        }).catch(() => {
            setBillingPortalLinkLoading(false)
        });
    }


    const getCurrentPlan = () => {
        paymentApiService.currentPlan().then((response) => {
            setCurrentPlan(response)
            setCurrentPlanLoading(false)
        }).catch((e) => {
            console.error(e)
        })
    }

    const skeletonComponent = () => {
        return (
            <Box marginY={1}>
                {[4, 2, 8, 7, 3, 10, 7, 8, 9, 4].map((item, key) => (
                    <Skeleton key={key} height={10} width={item * 10 + '%'}/>
                ))}
            </Box>
        )
    }

    const onClickEditSubscription = () => {
        if (billingPortalLink) {
            window.open(billingPortalLink, '_self');
        }
    }

    useEffect(() => {
        getEditSubscriptionLink();
        getCurrentPlan();
    }, [])

    return (
        <Card variant="outlined">
            <CardHeader title={t('SETTINGS.TAB_BILLING.CARD_PLAN.TITLE')}
                        action={buttonEditSubscription()}></CardHeader>
            <CardContent>
                {currentPlanLoading && skeletonComponent()}
                {!currentPlanLoading && currentPlan && <Grid container>
                    <Grid item xs={12} md={6}>
                        <Stack direction="row">
                            <div>
                                {currentPlan.title} - {currentPlan.description} -
                                € {formatStripePrice(currentPlan.price)} / {currentPlan.billingCadence === "month" ? t('COMPLETE_PROFILE.SELECT_PLAN.MONTH') : t('COMPLETE_PROFILE.SELECT_PLAN.YEAR')}
                                <Chip color="success" size="small" sx={{marginLeft: 1}} label={t('ACTIVE')}/>
                            </div>
                        </Stack>
                    </Grid>
                </Grid>}
            </CardContent>
        </Card>
    );
}

export default PlanCard;
