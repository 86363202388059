import JSZip from "jszip";
import React, {useCallback, useEffect, useState} from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useDropzone} from "react-dropzone";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import BasicButton from "../../../compontensNuovi/buttons/BasicButton";
import {t} from "i18next";

import styles from "./TabSeiModale.module.css";

const TabSei = ({ handleFileUpload, handleTabCompletion, tabIndex }: any) => {
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
	const [open, setOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const allowedExtensions = [".pdf", ".jpg", ".jpeg", ".png"];

	useEffect(() => {
		handleTabCompletion(tabIndex, true);
	}, []);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setErrorMessage("");
		setOpen(false);
		setSelectedFiles([]);
	};

	const isExtensionAllowed = (fileName: string): boolean => {
		const fileExtension = fileName.substring(fileName.lastIndexOf(".")).toLowerCase();
		const isAllowed = allowedExtensions.includes(fileExtension);
		if (!isAllowed) {
			setErrorMessage(`Il file ${fileExtension}" non è consentito.`);
		} else {
			setErrorMessage(""); // Reset error message when a valid file is added
		}
		return isAllowed;
	};

	const onDrop = useCallback((acceptedFile: File[]) => {
		const validFiles = acceptedFile.filter((file) => isExtensionAllowed(file.name));
		setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	//elimina i file non ancora
	const handleRemoveFile = (index: number) => {
		setSelectedFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
	};

	const handleRemoveUploadedFile = async (index: number) => {
		setUploadedFiles((prevFiles) => {
			const updatedFiles = prevFiles.filter((file, i) => i !== index);
			generateZipFile(updatedFiles, selectedFiles);
			return updatedFiles;
		});
	};

	const generateZipFile = async (uploadedFilesToZip: File[], selectedFilesToZip: File[]) => {
		const zip = new JSZip();

		const allFiles = [...uploadedFilesToZip, ...selectedFilesToZip];
		if (allFiles.length > 0) {
			for (const file of allFiles) {
				if (isExtensionAllowed(file.name)) {
					const fileContents = await new Promise<Blob>((resolve, reject) => {
						const reader = new FileReader();
						reader.onloadend = () => {
							const arrayBuffer = reader.result as ArrayBuffer;
							resolve(new Blob([arrayBuffer]));
						};
						reader.onerror = reject;
						reader.readAsArrayBuffer(file);
					});
					zip.file(file.name, fileContents);
				}
			}
		}

		const content = await zip.generateAsync({ type: "blob" });
		handleFileUpload(content);
		setSelectedFiles([]); // reset selected files
		setUploadedFiles([...allFiles]); // update uploaded files with the current state
		handleClose();
	};

	const handleClickGenerateZip = async () => {
		try {
			await generateZipFile(uploadedFiles, selectedFiles);
		} catch (error) {
			console.error("Error generating zip file:", error);
			// Handle the error as needed (e.g., show an error message)
		}
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose} PaperProps={{ className: styles.dialog }}>
				<DialogTitle>{t("FORM_REPORTS.SIXTH_TAB.UPLOAD_FILE")}</DialogTitle>
				<DialogContent>
					<div style={{ display: "flex", marginBottom: "10px", fontSize: "0.9rem" }}>
						{t("FORM_REPORTS.SIXTH_TAB.PERMITTED_FORMATS")}{" "}
						{allowedExtensions.map((extension, index) => (
							<React.Fragment key={extension}>
								{index !== 0 && " "}
								{extension}
							</React.Fragment>
						))}
					</div>
					<div {...getRootProps()} className={`${styles.dropzone} ${isDragActive ? styles.activeDropzone : ""}`}>
						<input {...getInputProps()} />
						{isDragActive ? <p>{t("FORM_REPORTS.SIXTH_TAB.DROP_FILE")}</p> : <p>{t("FORM_REPORTS.SIXTH_TAB.DRAG_DROP_FILE")} </p>}
					</div>
					{selectedFiles.length > 0 && (
						<List>
							{selectedFiles.map((file, index) => (
								<ListItem key={index}>
									<ListItemText primary={file.name} />
									<IconButton onClick={() => handleRemoveFile(index)}>
										<DeleteIcon />
									</IconButton>
								</ListItem>
							))}
						</List>
					)}
					{errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
				</DialogContent>
				<DialogActions>
					<BasicButton onClick={handleClickGenerateZip} text={t("OK")} maxWidth="fit-content" type="button" />

					<BasicButton
						onClick={handleClose}
						text={t("CANCEL")}
						maxWidth="fit-content"
						type="button"
						backgroundColor="trasparent"
						backgroundColorHover="var( --grey-500)"
						borderColorHover="var( --grey-500)"
						borderColor="black"
					/>
				</DialogActions>
			</Dialog>
			{uploadedFiles.length > 0 && (
				<List>
					{uploadedFiles.map((file, index) => (
						<ListItem sx={{ padding: "0 !important " }} key={index}>
							<ListItemText primary={file.name} />
							<IconButton onClick={() => handleRemoveUploadedFile(index)}>
								<DeleteIcon />
							</IconButton>
						</ListItem>
					))}
				</List>
			)}
			<BasicButton onClick={handleClickOpen} text={t("FORM_REPORTS.SIXTH_TAB.UPLOAD_FILE")} maxWidth="50%" type="button" />
		</div>
	);
};

export default TabSei;
