import React, {useEffect, useState} from "react";
import {FormControlLabel, Radio, TextField} from "@mui/material";
import styles from "./TabUno.module.css";
import {t} from "i18next";
import {radioStyleSX, radioTextStyleSX} from "../../../assets/CustomStyles";
import {capitalizeFirstLetter} from "../../../utils/helperFunctions";
import {CoreApiService} from "../../../services/CoreApiService";
import {ICommonType} from "../../../types/commons";

const TabUno = ({updateReportField, handleTabCompletion, tabIndex}: any) => {
	const [selectedItemCode, setSelectedItemCode] = useState<string | null>(null);
	const [descriptionValue, setDescriptionValue] = useState<string>("");
	const [isDescriptionRequired, setIsDescriptionRequired] = useState<boolean>(false);
	const [prevDescriptionValue, setPrevDescriptionValue] = useState<string>("");
	const [reportType, setReportType] = useState<ICommonType[]>([]);

	// const {reportType} = useContext(MyContext)!;
	const reportService = new CoreApiService();

	useEffect(() => {
		const fetchData = async () => {
			const report = await reportService.getReportTypes();
			setReportType(report);
		};
		fetchData();
	}, []);

	const handleItemChange = (itemCode: string) => {
		setSelectedItemCode(itemCode);
		let customReportType = "";
		let otherReportType = false;

		if (itemCode === "ALTRO") {
			otherReportType = true;
			setIsDescriptionRequired(true);
		} else {
			customReportType = itemCode;
			setIsDescriptionRequired(false);
			setDescriptionValue("");
			updateReportField({
				report_type: customReportType,
				other_report_type: otherReportType,
				other_report_type_description: "",
			});
		}
		handleTabCompletion(tabIndex, true);
	};

	const handleCustomItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDescriptionValue(event.target.value);
	};

	useEffect(() => {
		if (selectedItemCode === "ALTRO") {
			updateReportField({
				report_type: "",
				other_report_type: true,
				other_report_type_description: descriptionValue,
			});
			setPrevDescriptionValue(descriptionValue);
		}
	}, [descriptionValue, selectedItemCode, updateReportField]);

	return (
		<>
			<div>
				{reportType.map((item: ICommonType) => (
					<div key={item._id} className={styles.containerText}>
						<FormControlLabel
							control={<Radio sx={radioStyleSX} checked={selectedItemCode === item.code} onChange={() => handleItemChange(item.code)} />}
							label={capitalizeFirstLetter(item.description.toLowerCase())}
							sx={radioTextStyleSX}
						/>
					</div>
				))}
				<div className={`${styles.containerText} ${styles.textField}`}>
					<FormControlLabel
						control={<Radio sx={radioStyleSX} checked={selectedItemCode === "ALTRO"} onChange={() => handleItemChange("ALTRO")} />}
						label={capitalizeFirstLetter(t("OTHER"))}
						sx={radioTextStyleSX}
					/>
					<TextField
						label={t("DESCRIPTION")}
						value={descriptionValue}
						required={isDescriptionRequired}
						error={isDescriptionRequired && descriptionValue === ""}
						onChange={handleCustomItemChange}
						disabled={selectedItemCode !== "ALTRO"}
						className={`${selectedItemCode !== "ALTRO" ? styles.textFieldDisabled : styles.textField}`}
					/>
				</div>
			</div>
		</>
	);
};

export default TabUno;
