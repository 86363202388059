import React, {useEffect, useState} from "react";
import {Box, CircularProgress, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography} from "@mui/material";
import {t} from "i18next";
import {formatDate, generateColorMap} from "../../utils/helperFunctions";
import styles from "./Commenti.module.css";
import SendIcon from "@mui/icons-material/Send";
import AvatarWithColor from "../../compontensNuovi/AvatarColors/AvatarColors";

import {ToastContainer} from "react-toastify";
import {toast_error, toast_success} from "../../utils/custom-toast";
import {IReportComment} from "../../types/report";
import {CoreApiService} from "../../services/CoreApiService";
import { useSelector } from "react-redux";
import { EAuthSessionData } from "../../types/auth";

interface CommentiProps {
	comments: IReportComment[];
	id: string | undefined;
	reporterName?: string;
	refresh: boolean;
	setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const Commenti: React.FC<CommentiProps> = ({comments, id, reporterName, setRefresh, refresh}) => {
	const [newComment, setNewComment] = useState("");
	const [sortedComments, setSortedComments] = useState<IReportComment[]>([]);
	const [colorMapUser, setColorMapUser] = useState<Record<string, string>>({});
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingNewComment, setIsLoadingNewComment] = useState(false);

	const userName = useSelector((state: any) => state.user.username);
	const hasToken = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN);
	const coreApiService = new CoreApiService();

	let commenter: any;
	if (userName) {
		commenter = userName;
	} else if (reporterName) {
		commenter = reporterName;
	} else {
		commenter = t("ANONYMOUS");
	}

	let requestBody = {};

	if (hasToken) {
		requestBody = {
			id_report: id,
			username: commenter,
			description: newComment,
		};
	} else if (!hasToken && reporterName) {
		requestBody = {
			id_report: id,
			username: reporterName,
			description: newComment,
		};
	} else {
		requestBody = {
			id_report: id,
			username: t("ANONYMOUS"),
			description: newComment,
		};
	}

	useEffect(() => {
		const sorted = [...comments].sort((a, b) => new Date(b.createdAt ?? Date.now()).getTime() - new Date(a.createdAt ?? Date.now()).getTime());
		setSortedComments(sorted);
		setIsLoading(false);
	}, [comments]);

	useEffect(() => {
		setColorMapUser(generateColorMap(comments));
	}, [comments]);

	const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setNewComment(event.target.value);
	};

	// const handleCommentSubmit = async (event: React.FormEvent) => {
	// 	event.preventDefault();
	// 	setIsLoadingNewComment(true);

	// 	if (newComment.trim() !== "") {
	// 		try {
	// 			const data =
	// 				hasToken === null ? await coreApiService.createReportCommentPublic(requestBody) : await coreApiService.createReportComment(requestBody);
	// 			if (data) {
	// 				setRefresh(!refresh);
	// 				setSortedComments((prevComments) => [data, ...prevComments]);
	// 				setNewComment("");
	// 				toast_success(t("OPERATION_SUCCESS"));
	// 			} else {
	// 				toast_error(t("OPERATION_ERROR"));
	// 			}
	// 		} catch (error: any) {
	// 			toast_error(error);
	// 		} finally {
	// 			setIsLoadingNewComment(false);
	// 		}
	// 	}
	// };

	const handleCommentSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		setIsLoadingNewComment(true);

		if (!newComment.trim()) {
			toast_error(t("COMMENT_CANNOT_BE_EMPTY"));
			setIsLoadingNewComment(false);
			return;
		}

		try {
			const data =
				hasToken === null ? await coreApiService.createReportCommentPublic(requestBody) : await coreApiService.createReportComment(requestBody);
			if (data) {
				setRefresh(!refresh);
				setSortedComments((prevComments) => [data, ...prevComments]);
				setNewComment("");
				toast_success(t("OPERATION_SUCCESS"));
			} else {
				toast_error(t("OPERATION_ERROR"));
			}
		} catch (error: any) {
			toast_error(error.toString());
		} finally {
			setIsLoadingNewComment(false);
		}
	};

	return (
		<>
			<ToastContainer />
			<div className={styles.commentTitle}>{t("COMMENTS")}</div>
			<Box sx={{backgroundColor: "var(--grey-100)", padding: "10px 30px 35px 30px"}}>
				{isLoading ? ( // Controllo per l'indicatore di caricamento
					<div>Loading...</div>
				) : (
					<List>
						<ListItem alignItems="center">
							<ListItemAvatar>
								<ListItemAvatar>
									<AvatarWithColor username={commenter} />
								</ListItemAvatar>
							</ListItemAvatar>
							<ListItemText
								primary={
									<TextField
										multiline
										rows={2}
										variant="outlined"
										margin="normal"
										fullWidth
										value={newComment}
										onChange={handleCommentChange}
										placeholder={t("WRITE_COMMENT") + "..."}
										sx={{backgroundColor: "var(--white)"}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													{isLoadingNewComment ? (
														<CircularProgress size={24} />
													) : (
														<IconButton
															onClick={handleCommentSubmit}
															type="button"
															disabled={!newComment.trim()} // Disabilita se il commento è vuoto o contiene solo spazi
														>
															<SendIcon />
														</IconButton>
													)}
												</InputAdornment>
											),
										}}
									/>
								}
							/>
						</ListItem>
						{sortedComments.map((comment: IReportComment) => (
							<ListItem key={comment._id} alignItems="flex-start">
								<ListItemAvatar>
									<AvatarWithColor username={comment.username} colorMap={colorMapUser} />
								</ListItemAvatar>
								<div style={{width: "100%"}}>
									<div>
										<span className={styles.usernameStyle}>{comment.username}</span>
										<span className={styles.dateStyle}>{formatDate(comment.createdAt, true)}</span>
									</div>
									<div className={styles.commentContainer}>{comment.description}</div>
								</div>
							</ListItem>
						))}
					</List>
				)}
			</Box>
		</>
	);
};

export default Commenti;
