import {IPlan} from "../../../types/payment";
import {colors} from "../../../theme";
import {Box, Button, Card, CardContent, Chip, Collapse, Divider, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {formatStripePrice} from "../../../utils/helperFunctions";
import GppGoodIcon from '@mui/icons-material/GppGood';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Spinner} from "react-bootstrap";

interface IProps {
    selectedPlan: IPlan | null;
    isSubmitting: boolean;
    haveBillingInfo: boolean;
    checkout: () => void;
}

const Cart = ({selectedPlan, checkout, isSubmitting, haveBillingInfo}: IProps) => {
    const {t} = useTranslation();
    const [openHave, setOpenHave] = useState(false);

    const monthlyPriceWithIVA = (plan: IPlan): string => {
        if (plan.billingCadence === 'month') {
            return formatStripePrice(plan.price);
        }

        let price = Number(formatStripePrice(plan.price));
        let iva = price - (price / 1.22);
        price -= iva;
        return (price / 12).toFixed(2);
    }

    const totalWithoutTax = (plan: IPlan): string => {
        let price = Number(formatStripePrice(plan.price));
        let iva = price - (price / 1.22);
        price -= iva;
        return price.toFixed(2);
    }

    const totalTax = (plan: IPlan): string => {
        let price = Number(formatStripePrice(plan.price));
        let iva = price - (price / 1.22);
        return iva.toFixed(2);
    }

    const trialDays = (plan: IPlan): string => {
        return `${plan.trial_day}`;
    }

    const nextBillingDate = (plan: IPlan): string => {
        var date = new Date();
        date.setDate(date.getDate() + plan.trial_day);
        return date.toLocaleDateString();
    }

    return (
        <Card elevation={0} sx={{
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: colors.tertiary,
            backgroundColor: colors.tertiary
        }}>
            <CardContent>
                <Box marginX={2}>
                    <Typography variant="subtitle1" component="h3" align="left">
                        {t('COMPLETE_PROFILE.CART.TITLE')}
                    </Typography>
                    <Stack spacing={2} marginY={4}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body1" component="p">
                                {t('COMPLETE_PROFILE.CART.SUB_NAME')}
                            </Typography>
                            <Typography variant="body1" component="p" fontWeight="bold">
                                {selectedPlan?.title ?? t('COMPLETE_PROFILE.CART.NO_PLAN')}
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body1" component="p">
                                {t('COMPLETE_PROFILE.CART.HAVE_FREE_TRIAL')}
                            </Typography>
                            {!selectedPlan && <Typography variant="body1" component="p" fontWeight="bold">
                                {t('COMPLETE_PROFILE.CART.NO_PLAN')}
                            </Typography>}
                            {selectedPlan && selectedPlan.trial_day === 0 &&
                                <Typography variant="body1" component="p" fontWeight="bold">
                                    {t('COMPLETE_PROFILE.CART.NONE')}
                                </Typography>}
                            {selectedPlan && selectedPlan.trial_day > 0 &&
                                <Chip label={selectedPlan.trial_day + ' ' + t('COMPLETE_PROFILE.CART.DAYS')}
                                      color="success"/>}
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body1" component="p">
                                {t('COMPLETE_PROFILE.CART.BILLED_ON')}
                            </Typography>
                            <Typography variant="body1" component="p" fontWeight="bold">
                                {selectedPlan ? selectedPlan.billingCadence === 'month' ? t('COMPLETE_PROFILE.CART.BILLED_ON_MONTH') : t('COMPLETE_PROFILE.CART.BILLED_ON_YEAR') : t('COMPLETE_PROFILE.CART.NO_PLAN')}
                            </Typography>
                        </Stack>
                        <Box display="flex" justifyContent="end">
                            <Stack direction="row">
                                <Typography variant="h2" component="p">
                                    € {selectedPlan && <>{monthlyPriceWithIVA(selectedPlan)}</>}
                                    {!selectedPlan && <>0.00</>}
                                </Typography>
                                <Box display="flex" alignItems="flex-end" marginBottom="10px" marginLeft="5px">
                                    <Typography variant="body1" align="right"
                                                component="p">/{t('COMPLETE_PROFILE.CART.MONTH')}</Typography>
                                </Box>
                            </Stack>
                        </Box>
                        <Divider/>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body1" component="p">
                                {t('COMPLETE_PROFILE.CART.TOTAL_WITHOUT_TAX')}
                            </Typography>
                            <Typography variant="body1" component="p" fontWeight="bold">
                                € {selectedPlan && <>{totalWithoutTax(selectedPlan)}</>}
                                {!selectedPlan && <>0.00</>}
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body1" component="p">
                                {t('COMPLETE_PROFILE.CART.TOTAL_TAX')}
                            </Typography>
                            <Typography variant="body1" component="p" fontWeight="bold">
                                € {selectedPlan && <>{totalTax(selectedPlan)}</>}
                                {!selectedPlan && <>0.00</>}
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body1" component="p" fontWeight="bold">
                                {t('COMPLETE_PROFILE.CART.TOTAL')}
                            </Typography>
                            <Typography variant="body1" component="p" fontWeight="bold">
                                € {formatStripePrice(selectedPlan?.price ?? '000')}
                            </Typography>
                        </Stack>
                        <Divider/>
                        {selectedPlan && selectedPlan.have.length &&
                            <Box textAlign="right">
                                <Button size="small" startIcon={<ExpandMoreIcon/>}
                                        onClick={() => setOpenHave(!openHave)}>
                                    {t('COMPLETE_PROFILE.CART.HAVE')}
                                </Button>
                            </Box>}
                        <div>
                            <Collapse in={openHave}>
                                <Stack>
                                    {selectedPlan?.have.map((feature, key) => (
                                        <span key={key}><CheckIcon color="success"/> {feature}</span>
                                    ))}
                                </Stack>
                            </Collapse>
                        </div>
                        <Box paddingTop="20px">
                            {!isSubmitting &&
                                <Button variant="contained" disabled={!selectedPlan || !haveBillingInfo} fullWidth
                                        disableElevation
                                        size="large"
                                        onClick={checkout}
                                        color="secondary">
                                    <Box paddingY="5px">
                                        <Typography variant="body1" component="p" fontWeight="bold">
                                            {t('COMPLETE_PROFILE.CART.SUBMIT')} {selectedPlan && selectedPlan.trial_day > 0 && '*'}
                                        </Typography>
                                    </Box>
                                </Button>}
                            {isSubmitting &&
                                <Button variant="contained" fullWidth disableElevation
                                        size="large"
                                        color="secondary">
                                    <Box paddingY="5px">
                                        <Spinner animation="border"/>
                                    </Box>
                                </Button>}
                        </Box>
                        <Typography variant="body1" component="p" align="center">
                            <GppGoodIcon color="success"/> {t('COMPLETE_PROFILE.CART.SECURE')}
                        </Typography>
                        {selectedPlan && selectedPlan.trial_day > 0 &&
                            <Typography variant="body2"
                                        component="p">{t('COMPLETE_PROFILE.CART.TRIAL_TERMS', {
                                days: trialDays(selectedPlan),
                                date: nextBillingDate(selectedPlan)
                            })}</Typography>}
                    </Stack>
                </Box>
            </CardContent>
        </Card>
    )
}

export default Cart;
