import {Box, FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import {t} from "i18next";
import {useEffect, useState} from "react";
import styles from "./TabQuattro.module.css";
import {radioStyleSX} from "../../../assets/CustomStyles";

const TabQuattro = ({ updateReportField, handleTabCompletion, tabIndex }: any) => {
	const [otherSubjects, setOtherSubjects] = useState(false);
	const [subjectInvolved, setSubjectInvolved] = useState("");

	const [authorityReport, setAuthorityReport] = useState(false);
	const [authorityReportDescription, setAuthorityReportDescription] = useState("");

	const [talkWithSomeone, setTalkWithSomeone] = useState(false);
	const [talkWithSomeoneDescription, setTalkWithSomeoneDescription] = useState("");

	//===================== GROUP 1 =====================

	const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value === "true";
		setOtherSubjects(newValue);
		if (newValue) {
			updateReportField({
				other_subjects_can_report_facts: true,
			});
		} else {
			updateReportField({
				other_subjects_can_report_facts: false,
				other_subjects_can_report_facts_description: "",
			});
			setSubjectInvolved("");
		}
	};

	const handleSubjectInvolvedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newSubject = event.target.value;
		setSubjectInvolved(newSubject);
		updateReportField({
			other_subjects_can_report_facts_description: newSubject,
		});
	};

	//===================== GROUP 2 =====================
	const handleAuthorityReportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value === "true";
		setAuthorityReport(newValue);
		if (newValue) {
			updateReportField({
				report_another_authority: true,
			});
		} else {
			updateReportField({
				report_another_authority: false,
				report_another_authority_description: "",
			});
			setAuthorityReportDescription("");
		}
	};

	const handleAuthorityReportDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newDescription = event.target.value;
		setAuthorityReportDescription(newDescription);
		updateReportField({
			report_another_authority_description: newDescription,
		});
	};

	//===================== GROUP 3 =====================
	const handleTalkWithSomeoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value === "true";
		setTalkWithSomeone(newValue);
		if (newValue) {
			updateReportField({
				talk_with_someone_about_what_happened: true,
			});
		} else {
			updateReportField({
				talk_with_someone_about_what_happened: false,
				talk_with_someone_about_what_happened_description: "",
			});
			setTalkWithSomeoneDescription("");
		}
	};

	const handleTalkWithSomeoneDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newDescription = event.target.value;
		setTalkWithSomeoneDescription(newDescription);
		updateReportField({
			talk_with_someone_about_what_happened_description: newDescription,
		});
	};

	useEffect(() => {
		const group1Valid = otherSubjects ? subjectInvolved !== "" : true;
		const group2Valid = authorityReport ? authorityReportDescription !== "" : true;
		const group3Valid = talkWithSomeone ? talkWithSomeoneDescription !== "" : true;

		if (group1Valid && group2Valid && group3Valid) {
			handleTabCompletion(tabIndex, true);
		} else {
			handleTabCompletion(tabIndex, false);
		}
	}, [
		otherSubjects,
		subjectInvolved,
		authorityReport,
		authorityReportDescription,
		talkWithSomeone,
		talkWithSomeoneDescription,
		handleTabCompletion,
		tabIndex,
	]);

	return (
		<>
			<div>
				<RadioGroup name="period" value={otherSubjects} onChange={handlePeriodChange} row>
					<FormControlLabel value="true" control={<Radio sx={radioStyleSX} />} label={t("YES")} />
					<FormControlLabel value="false" control={<Radio sx={radioStyleSX} />} label={t("NO")} />
				</RadioGroup>
				<p className={styles.subTitle}>{t("FORM_REPORTS.FOURTH_TAB.SUBTITLE_SUBJECT")}</p>
				<TextField
					className={`${!otherSubjects ? styles.disabledTextField : styles.textField}`}
					value={subjectInvolved}
					onChange={handleSubjectInvolvedChange}
					required
					disabled={!otherSubjects}
					error={authorityReport && authorityReportDescription === ""}
				/>
			</div>
			{/* ===================== */}
			{/* GROUP 2 */}
			<Box>
				<div className={styles.title}>{t("FORM_REPORTS.FOURTH_TAB.AUTHORITY_TITLE")}</div>
				<RadioGroup name="period" value={authorityReport} onChange={handleAuthorityReportChange} row>
					<FormControlLabel value="true" control={<Radio sx={radioStyleSX} />} label={t("YES")} />
					<FormControlLabel value="false" control={<Radio sx={radioStyleSX} />} label={t("NO")} />
				</RadioGroup>
				<p className={styles.subTitle}>{t("FORM_REPORTS.FOURTH_TAB.SUBTITLE_AUTHORITY")}</p>
				<TextField
					className={`${!authorityReport ? styles.disabledTextField : styles.textField}`}
					value={authorityReportDescription}
					onChange={handleAuthorityReportDescriptionChange}
					required
					disabled={!authorityReport}
					error={authorityReport && authorityReportDescription === ""}
				/>
			</Box>

			{/* ===================== */}
			{/* GROUP 3 */}
			<Box>
				<div className={styles.title}>{t("FORM_REPORTS.FOURTH_TAB.TALKSOMEONE_TITLE")}</div>
				<RadioGroup name="period" value={talkWithSomeone} onChange={handleTalkWithSomeoneChange} row>
					<FormControlLabel value="true" control={<Radio sx={radioStyleSX} />} label={t("YES")} />
					<FormControlLabel value="false" control={<Radio sx={radioStyleSX} />} label={t("NO")} />
				</RadioGroup>
				<p className={styles.subTitle}>{t("FORM_REPORTS.FOURTH_TAB.SUBTITLE_TALKSOMEONE")}</p>
				<TextField
					className={`${!talkWithSomeone ? styles.disabledTextField : styles.textField}`}
					value={talkWithSomeoneDescription}
					onChange={handleTalkWithSomeoneDescriptionChange}
					required
					disabled={!talkWithSomeone}
					error={talkWithSomeone && talkWithSomeoneDescription === ""}
				/>
			</Box>
		</>
	);
};

export default TabQuattro;
