import {IPlan} from "../../../types/payment";
import {Box, Grid, Paper} from "@mui/material";
import {colors} from "../../../theme";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {formatStripePrice} from "../../../utils/helperFunctions";

interface IProps {
    plans: IPlan[];
    currentPlan: IPlan | null;
    handlePlanChange: (plan: IPlan) => void;
}

const SelectPlan = ({plans = [], handlePlanChange, currentPlan = null}: IProps) => {

    const {t} = useTranslation();
    const onPlanChange = (plan: IPlan) => {
        setSelectedPlan(plan);
        handlePlanChange(plan);
    }

    const [selectedPlan, setSelectedPlan] = useState<IPlan | null>(currentPlan);
    return (<>
        {plans.map((plan, key) => (
            <Box key={key} marginY="10px">
                <Paper variant="outlined" sx={{
                    cursor: "pointer",
                    borderColor: selectedPlan?._id === plan._id ? colors.black : null
                }}
                       onClick={() => onPlanChange(plan)}>
                    <Box padding={3}>
                        <Grid container>
                            <Grid item xs={1}>
                                {selectedPlan?._id === plan._id ?
                                    <CheckCircleIcon color="success"/> :
                                    <RadioButtonUncheckedIcon/>}
                            </Grid>
                            <Grid item xs={11}>
                                {plan.title} - {plan.description} -
                                {plan.trial_day === 0 && <> € {formatStripePrice(plan.price)} / {plan.billingCadence === "month" ? t('COMPLETE_PROFILE.SELECT_PLAN.MONTH') : t('COMPLETE_PROFILE.SELECT_PLAN.YEAR')}</>}
                                {plan.trial_day > 0 && <> {t('COMPLETE_PROFILE.SELECT_PLAN.TRIAL_TEXT', {days: plan.trial_day})} € {formatStripePrice(plan.price)} / {plan.billingCadence === "month" ? t('COMPLETE_PROFILE.SELECT_PLAN.MONTH') : t('COMPLETE_PROFILE.SELECT_PLAN.YEAR')}</>}
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        ))}
    </>)
}

export default SelectPlan;
