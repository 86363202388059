import React, {useEffect, useState} from "react";
import styles from "../HomeListaAziende/HomeListaAziende.module.css";
import {t} from "i18next";
import SearchBar from "../../componets/barraRicerca/SearchBar";
import {Table} from "react-bootstrap";
import {IconButton, Pagination} from "@mui/material";
import isPlatformAdminGuard from "../../guards/isPlatformAdminGuard";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {API_GET_REGISTRY_LIST_COUNT, API_GET_REGISTRY_LIST_SEARCH, REGISTRY_INFO} from "../../api/api";
import {toast_error} from "../../utils/custom-toast";
import {ERegistryType, IRegistry} from "../../types/registry";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {RegistryApiService} from "../../services/RegistryApiService";
import {useTranslation} from "react-i18next";
import { useSelector } from "react-redux";
import { EAuthSessionData } from "../../types/auth";

const ListaOrganizzazioni: React.FC = () => {
	const navigate = useNavigate();
	const {t} = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [totalItems, setTotalItems] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchValue, setSearchValue] = useState("");
	const role = useSelector((state: any) => state.user.role);
	const isPlatformAdmin = isPlatformAdminGuard(role);
	const [idOwner, setIdOwner] = useState("");
	const registryService = new RegistryApiService();

	const [azienda, setAzienda] = useState<IRegistry[]>([]);

	const aToken = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN);
	const itemsPerPage = 15;

	useEffect(() => {
		const fetchIdRegistryOwner = async () => {
			setIsLoading(true);
			try {
				const data = await registryService.infoMe();

				if (data) {
					setIdOwner(data._id ?? "");
				} else {
					toast_error(t("OPERATION_ERROR"));
				}
			} catch (error: any) {
				toast_error(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchIdRegistryOwner();
	}, []);

	const fetchTotalItemCount = async () => {
		try {
			const data = await registryService.countRegistry({
				registry_type: ERegistryType.AZIENDA,
				only_super_admin: true,
				searchFullText: searchValue,
				searchField: ["business_name"],
			});
			setTotalItems(data);
		} catch (error) {
			console.error(error);
			setTotalItems(0);
		}
	};

	const fetchData = async () => {
		setIsLoading(true);
		try {
			const data = await registryService.searchRegistry({
				page: currentPage,
				limit: itemsPerPage,
				registry_type: ERegistryType.AZIENDA,
				orderBy: "createdAt",
				sort: -1,
				searchFullText: searchValue,
				searchField: ["business_name"],
				only_super_admin: true,
			});
			setAzienda(data);
		} catch (error: any) {
			toast_error(error.data.message);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchTotalItemCount();
		if (searchValue.length >= 3 || searchValue.length === 0) {
			fetchData();
		}
	}, [currentPage, searchValue]);

	const handleSearch = (searchTerm: string) => {
		if (searchTerm.length >= 3) {
			setSearchValue(searchTerm.toLocaleLowerCase().trim());
			setCurrentPage(1);
		} else {
			setSearchValue("");
			setCurrentPage(1);
		}
	};

	const handleTableRowClick = (id: any) => {
		if (isPlatformAdmin && id) {
			navigate(`/OrganizationDetail/${id}`);
		}
	};

	return (
		<>
			<div className={styles.labels}>
				<div className={styles.subTitle}>{t("ORGANIZATION_TABLE.TITLE")}</div>
				<div style={{marginBottom: "20px"}}>
					<SearchBar onSearch={handleSearch} placeholder={t("SEARCH")} />
					{/* Add Organizzazione */}
					{/*<AddButton onClick={handleAddAzienda} text={t("COMPANY_TABLE.ADD_COMPANY")}/>*/}
				</div>
				<Table hover>
					<thead
						style={{
							backgroundColor: "var(--grey-100)",
							color: "black",
							borderBottom: "2px var(--grey-100) solid",
						}}
					>
						<tr>
							<th className={styles.tableHeaders}>{t("ORGANIZATION_TABLE.TABLE_TITLE.ORGANIZATION")}</th>
							<th className={styles.tableHeaders}>VAT</th>
							<th className={styles.tableHeaders}>Email</th>
							<th className={styles.tableHeaders}>{t("PHONE")}</th>
							<th className={styles.tableHeaders}>Tenant</th>
							<th className={styles.tableHeaders}>Detail</th>
						</tr>
					</thead>
					<tbody>
						{isLoading ? (
							<tr>
								<td colSpan={4} className={styles.loadingRow}>
									Loading...
								</td>
							</tr>
						) : (
							azienda?.map((azienda) => (
								<tr key={azienda._id} className={`${styles.rowStyle} ${styles.tableRow}`} onDoubleClick={() => handleTableRowClick(azienda._id)}>
									<td className={styles.rowStyle}>{azienda.business_name}</td>
									<td className={styles.rowStyle}>{azienda.vat_number}</td>
									<td className={styles.rowStyle}>
										{azienda.id_contact.find((contact: any) => contact.id_contact_type && contact.id_contact_type.code === "EMAIL")?.contact}
									</td>
									<td className={styles.rowStyle}>
										{azienda.id_contact.find((contact: any) => contact.id_contact_type && contact.id_contact_type.code === "PHONE")?.contact}
									</td>
									<td className={styles.rowStyle}>{azienda.tenant}</td>
									<td className={styles.rowStyle}>
										<IconButton onClick={() => handleTableRowClick(azienda._id)} type="button">
											<VisibilityIcon />
										</IconButton>
									</td>
								</tr>
							))
						)}
					</tbody>
				</Table>
				{!isLoading ? (
					<Pagination
						count={Math.ceil(totalItems / itemsPerPage)}
						page={currentPage}
						onChange={(_, page) => setCurrentPage(page)}
						shape="rounded"
						className={styles.pagination}
						showFirstButton
					/>
				) : null}
			</div>
		</>
	);
};
export default ListaOrganizzazioni;
