import {Box, Card, CardContent, CardHeader, Skeleton} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FormFatturazione from "../../../../CompletaProfiloPages/components/FormFatturazione";
import {IBillingInfo} from "../../../../../types/payment";
import billingInfoInitialValues from "../../../../../formModels/billingInfo/billingInfoInitialValues";
import {PaymentApiService} from "../../../../../services/PaymentApiService";

const FatturazioneCard = () => {
    const {t} = useTranslation();
    const [bInfoInitialValues, setBInfoInitialValues] = useState<IBillingInfo>(billingInfoInitialValues);
    const [haveBillingInfo, setHaveBillingInfo] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const handleHaveBillingInfo = (haveBillingInfo: boolean): void => {
        setHaveBillingInfo(haveBillingInfo);
    }

    const paymentApiService = new PaymentApiService();

    const skeletonComponent = () => {
        return (
            <Box marginY={1}>
                {[4, 2, 8, 7, 3, 10, 7, 8, 9, 4].map((item, key) => (
                    <Skeleton key={key} height={60} width={item * 10 + '%'}/>
                ))}
            </Box>
        )
    }

    useEffect(() => {
        paymentApiService.billingInfo().then((response) => {
            if (response) {
                setBInfoInitialValues(response);
                setHaveBillingInfo(true);
                setIsLoading(false);
            }
        }).catch(() => {
        })
    }, [])

    return (
        <Card variant="outlined">
            <CardHeader title={t('SETTINGS.TAB_BILLING.CARD_BILLING.TITLE')}></CardHeader>
            <CardContent>
                {isLoading && skeletonComponent()}
                {!isLoading && <FormFatturazione initialValues={bInfoInitialValues}
                                                 handleHaveBillingInfo={handleHaveBillingInfo}
                                                 haveBilling={haveBillingInfo}/>}
            </CardContent>
        </Card>
    );
}

export default FatturazioneCard;
