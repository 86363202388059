import {Button} from "@mui/material";
import React from "react";


type AddButtonProps = {
	onClick: () => void;
	text?: string;
	fontSizeText?: string;
};

const AddButton: React.FC<AddButtonProps> = ({ onClick, text, fontSizeText = "1rem" }) => {
	return (
    <Button
      onClick={onClick}
      variant="outlined"
      sx={{
      backgroundColor: "black",
      color: "white",
      fontSize: "1rem",
      marginLeft: '10px',
      width: "fit-content",
      height: "3rem",
      padding: "12px 16px",
      cursor: "pointer", 
      alignItems: "center", 
      textTransform: "Capitalize",
      "&:active": {
          borderColor: "black",
      },
      "&:hover": {
          borderColor: "var(--grey-400)",
          bgcolor: "var(--yellow-500)",
          color: "black",
      },
      }}
    >
      <div style={{ fontSize: fontSizeText }}>{text}</div>
    </Button>
	);
};
export default AddButton;
