import {Grid} from "@mui/material";
import ProfiloCard from "./components/ProfiloCard";
import ChangePassword from "./components/ChangePassword";
import isSuperAdminGuard from "../../../../guards/isSuperAdminGuard";
import isPlatformAdminGuard from "../../../../guards/isPlatformAdminGuard";
import { useSelector } from "react-redux";

const ProfiloTab = () => {
	const role = useSelector((state: any) => state.user.role);
	const notSuperAdmin = !isSuperAdminGuard(role) || !isPlatformAdminGuard(role);
	return (
			<Grid container spacing={2}>
				{notSuperAdmin && (
					<Grid item xs={12} md={7}>
						<ProfiloCard />
					</Grid>
				)}
				<Grid item xs={12} md={5}>
					<ChangePassword />
				</Grid>
			</Grid>
	);
};

export default ProfiloTab;
