import React, {useEffect, useState} from "react";
import {t} from "i18next";
import styles from "./ThankYouPage.module.css";
import BasicButton from "../../../compontensNuovi/buttons/BasicButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {toast, ToastContainer} from "react-toastify";
import {toast_success} from "../../../utils/custom-toast";

type KeyCodeProps = {
	keyCode: string;
	onCopy: () => void;
};

const KeyCode: React.FC<KeyCodeProps> = ({keyCode, onCopy}) => (
	<div className={styles.keycodeContainer}>
		{keyCode}

		<div className={styles.copyIcon} onClick={onCopy}>
			<ContentCopyIcon />
		</div>
	</div>
);

const ThankYouPage: React.FC = () => {
	const tenant = sessionStorage.getItem("tenant") ?? "";
	const keyCode = sessionStorage.getItem("keyCode") ?? "";
	const [openDialog, setOpenDialog] = useState<boolean>(false);

	const handleCopyCode = (): void => {
		try {
			navigator.clipboard.writeText(keyCode);
			toast_success(t("THANK_YOU_PAGE.CODE_COPIED"), {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
			});
		} catch (err) {
			console.error("Errore durante la copia del keyCode:", err);
		}
	};

	const handleCloseDialog = (): void => setOpenDialog(false);
	const handleOpenDialog = (): void => setOpenDialog(true);
	const handleBacktoHome = (): string => (window.location.href = "/" + tenant);

	useEffect(() => {
		window.history.pushState(null, "");

		const handlePopState = (e: PopStateEvent): void => {
			e.preventDefault();
			handleOpenDialog();
		};

		window.addEventListener("popstate", handlePopState);

		return () => window.removeEventListener("popstate", handlePopState);
	}, []);

	const confirmText = t("CONFIRM");
	const cancelText = t("CANCEL");
	const thankYouText = t("THANK_YOU");

	return (
		<>
			<ToastContainer />
			{/* <ColorChangerButton /> */}
			<Dialog open={openDialog} onClose={handleCloseDialog}>
				<div style={{backgroundColor: " var(--grey-200)", padding: "15px"}}>
					<DialogTitle sx={{color: "black", fontWeight: 700}}>{t("THANK_YOU_PAGE.BACK_DIALOG.TITLE")}</DialogTitle>
					<DialogContent>
						<DialogContentText component="div" sx={{color: "black"}}>
							{t("THANK_YOU_PAGE.BACK_DIALOG.COPY_REMINDER")}
							<KeyCode keyCode={keyCode} onCopy={handleCopyCode} />
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<BasicButton onClick={handleBacktoHome} text={confirmText} />
						<BasicButton
							onClick={handleCloseDialog}
							text={cancelText}
							maxWidth="fit-content"
							type="button"
							backgroundColor="trasparent"
							backgroundColorHover="var( --grey-500)"
							borderColorHover="var( --grey-500)"
							borderColor="black"
						/>
					</DialogActions>
				</div>
			</Dialog>
			<div className={styles.container}>
				<Container>
					<div className={styles.header}>
						<div className={styles.title}>{thankYouText}</div>
					</div>

					<div className={styles.content}>
						<div className={styles.contentBold}>{t("THANK_YOU_PAGE.TITLE")}</div>
						<KeyCode keyCode={keyCode} onCopy={handleCopyCode} />
						<div className={styles.contentBold}>{t("THANK_YOU_PAGE.SUBTITLE")}</div>
						<BasicButton text="Torna alla home" onClick={handleOpenDialog} />
					</div>
				</Container>
			</div>
		</>
	);
};

export default ThankYouPage;
