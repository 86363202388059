import React from "react";
import {FaFolder} from "react-icons/fa";
import {CoreApiService} from "../../services/CoreApiService";
import axios from "axios";
import {API_GET_DOWNLOAD} from "../../api/api";
import { EAuthSessionData } from "../../types/auth";

interface Attachment {
	_id: string;
	createdAt: string;
	description: string;
	filename: string;
	format: string;
	updatedAt: string;
}

interface FileDownloadProps {
	attachment: Attachment;
}

const getFileExtension = (format: string): string => {
	switch (format) {
		case "jpeg":
			return "image/jpeg";
		case "png":
			return "image/png";
		default:
			return "application/octet-stream";
	}
};

const FileDownload: React.FC<FileDownloadProps> = ({ attachment }) => {
	const reportApiService = new CoreApiService();
	const aToken = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN);
	// Problematica con configurazione axios, non viene correttamente modificata.
	const handleDownload = async () => {
		const { _id, filename, format } = attachment;
		try {
			const response = await axios.get(`${API_GET_DOWNLOAD}/${_id}`, {
				responseType: "blob",
				headers: {
					"x-access-token": aToken
				},
			});
			const url = window.URL.createObjectURL(new Blob([response.data], { type: getFileExtension(format) }));

			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", filename);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
		} catch (error) {
			console.error("Errore durante il download del file:", error);
		}
	};

	return (
		<div onClick={handleDownload} style={{ display: "flex", alignItems: "center", cursor: "pointer", fontSize: "25px" }}>
			<FaFolder style={{ marginRight: "5px" }} />
			<span>
				{attachment.filename}
				{attachment.format}
			</span>
		</div>
	);
};

export default FileDownload;
