import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Container,
    Grid,
    Skeleton,
    Typography
} from "@mui/material";
import Topbar from "../Topbar/Topbar";
import {useTranslation} from "react-i18next";
import FormFatturazione from "./components/FormFatturazione";
import React, {useEffect, useState} from "react";
import {IBillingInfo, IPlan} from "../../types/payment";
import billingInfoInitialValues from "../../formModels/billingInfo/billingInfoInitialValues";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {PaymentApiService} from "../../services/PaymentApiService";
import SelectPlan from "./components/SelectPlan";
import Cart from "./components/Cart";
import {IAlert} from "../../types/commons";
import {useLocation} from "react-router-dom";
import Footer from "../../componets/Footer/Footer";

const CompletaProfiloPage = () => {
    const [bInfoInitialValues, setBInfoInitialValues] = useState<IBillingInfo>(billingInfoInitialValues);
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [haveBillingInfo, setHaveBillingInfo] = useState<boolean>(false);
    const [plans, setPlans] = useState<IPlan[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<IPlan | null>(null);
    const [alert, setAlert] = useState<IAlert>({
        show: false,
        severity: "error",
        message: "",
        dismissible: true,
        onClose: () => {
            setAlert({...alert, show: false})
        }
    });

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const errorParam = searchParams.get('error');


    const paymentApiService = new PaymentApiService();

    const getPlans = () => {
        paymentApiService.getPlans().then((response) => {
            setPlans(response);
            setSelectedPlan(response[0]);
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        paymentApiService.billingInfo().then((response) => {
            if (response) {
                setBInfoInitialValues(response);
                setHaveBillingInfo(true);
            }
            getPlans();
        }).catch(() => {
            getPlans();
        })

        if (errorParam) {
            setAlert({
                ...alert,
                show: true,
                message: t('COMPLETE_PROFILE.ERRORS.CHECKOUT_CANCELLED'),
            })
        }


    }, [])


    const skeletonComponent = () => {
        return (
            <Box marginY={1}>
                {[4, 2, 8, 7, 3, 10, 7, 8, 9, 4].map((item, key) => (
                    <Skeleton key={key} height={60} width={item * 10 + '%'}/>
                ))}
            </Box>
        )
    }

    const handlePlanChange = (plan: IPlan): void => {
        setSelectedPlan(plan);
    }

    const handleHaveBillingInfo = (haveBillingInfo: boolean): void => {
        setHaveBillingInfo(haveBillingInfo);
    }

    const startCheckout = () => {
        setIsSubmitting(true);
        if (selectedPlan) {
            paymentApiService.startCheckout(selectedPlan).then((response) => {
                window.location.href = response;
            }).catch(() => {
                setAlert({
                    ...alert,
                    show: true,
                    message: t('COMPLETE_PROFILE.ERRORS.START_CHECKOUT'),
                });
                setIsSubmitting(false);
            })
        }
    }


    return (
        <>
            <Topbar/>
            <Container maxWidth="xl">
                <Box marginY={10}>
                    <Grid container marginBottom={3}>
                        <Grid item xs={12}>
                            <Box mb={3}>
                                <Typography variant="h4" component="h1" align="center">
                                    {t('COMPLETE_PROFILE.TITLE')}
                                </Typography>
                            </Box>
                            <Typography variant="body1" component="h2" align="center">
                                {t('COMPLETE_PROFILE.SUBTITLE')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={8}>
                            {alert.show && <Alert className="mb-4" severity={alert.severity}
                                                  onClose={alert.onClose}>{t(alert.message)}</Alert>}
                            {isLoading && skeletonComponent()}
                            {!isLoading &&
                                <>
                                    <Accordion expanded={true} elevation={0}
                                               variant="outlined">
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                        >
                                            <Typography variant="subtitle1"
                                                        component="h3">{t('COMPLETE_PROFILE.ACCORDION_2.TITLE')} *
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <SelectPlan plans={plans} handlePlanChange={handlePlanChange}
                                                        currentPlan={selectedPlan}/>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={true} elevation={0} variant="outlined">
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                        >
                                            <Typography variant="subtitle1"
                                                        component="h3">{t('COMPLETE_PROFILE.ACCORDION_1.TITLE')} *
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <FormFatturazione initialValues={bInfoInitialValues}
                                                              handleHaveBillingInfo={handleHaveBillingInfo}
                                                              haveBilling={haveBillingInfo}/>
                                        </AccordionDetails>
                                    </Accordion>
                                </>}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Cart selectedPlan={selectedPlan} checkout={startCheckout} isSubmitting={isSubmitting}
                                  haveBillingInfo={haveBillingInfo}/>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Footer/>
        </>
    )
}

export default CompletaProfiloPage
