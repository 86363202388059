import React from "react";
import {Avatar} from "@mui/material";

interface AvatarWithColorProps {
	username: string;
	colorMap?: Record<string, string>;
}

const AvatarWithColor: React.FC<AvatarWithColorProps> = ({
	username,
	colorMap = {}, // impostare un oggetto vuoto come default per colorMap
}) => {
	const defaultColor = "var(--grey-200)"; // inserisci il colore di default che preferisci
	const color = colorMap[username] || defaultColor;

	return (
		<Avatar style={{ backgroundColor: color }}>
			{username
				.split(" ")
				.map((n: any) => n[0])
				.join("")
				.toUpperCase()}
		</Avatar>
	);
};

export default AvatarWithColor;
