import {MENU_URL} from "../api/api";
import {TApiListSearchParam} from "../types/commons";
import {linksBE} from "../types/report";

import {ApiService} from "./ApiService";

export class LinkApiService extends ApiService {
	constructor() {
		super(MENU_URL);
	}

	linkByTenantPrivacy<T>(tenant: string, params?: TApiListSearchParam<T>): Promise<linksBE[]> {
		const fullParams = {...params, link_type_code: "PRIVACY", tenant: tenant};
		return this.get<linksBE[]>("/link/list/link-from-tenant/", {params: fullParams});
	}

	linkByTenantGeneric<T>(tenant: string, params?: TApiListSearchParam<T>): Promise<linksBE[]> {
		const fullParams = {...params, link_type_code: "GENERIC", tenant: tenant};
		return this.get<linksBE[]>("/link/list/link-from-tenant/", {params: fullParams});
	}
}
