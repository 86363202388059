import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { MyContextProvider } from "./context/MyContext";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { Provider } from "react-redux";
import store from './redux/store'

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const csp = ` 
	default-src 'self';
	font-src 'self' data: fonts.gstatic.com maxcdn.bootstrapcdn.com;
	img-src 'self' data:;
	script-src 'strict-dynamic' 'nonce-**CSP_NONCE**' 'unsafe-inline' http: https:;
	style-src 'self'  https://fonts.googleapis.com https://maxcdn.bootstrapcdn.com 'sha256-47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=';
	object-src 'none';
	require-trusted-types-for 'script';
	base-uri 'none';
`;

// Da configurare Content-Security-Policy con Helmet su FE e header disabilitato su Nginx -> Nonce prodotto univocamente sull'app con uuid-v4 (verificare con ZAP la validità della modifica)

//add_header Content-Security-Policy "default-src 'self'; font-src 'self' data: fonts.gstatic.com maxcdn.bootstrapcdn.com;img-src 'self' data:; script-src 'self'; style-src 'self'  https://fonts.googleapis.com https://maxcdn.bootstrapcdn.com 'sha256-47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU='; object-src 'none'; require-trusted-types-for 'script'; base-uri 'none';";

//${process.env.REACT_APP_API_PROTOCOL}//${process.env.REACT_APP_API_URL}

const cache = createCache({
  key: "my-prefix-key",
  nonce: "nonce-**CSP_NONCE**",
  prepend: true,
});

root.render(
  // <React.StrictMode>
  <>
    {/* <Helmet> 
                <meta  
                    http-equiv="Content-Security-Policy" 
                    content={csp} /> 
            </Helmet>  */}
    {/* <HelmetProvider>
				<Helmet>
				<meta  
                    http-equiv="Content-Security-Policy" 
                    content={csp} /> 
				</Helmet>

			</HelmetProvider> */}
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <MyContextProvider>
          <Provider store={store}>
            <App />
          </Provider>  
          </MyContextProvider>
        </I18nextProvider>
      </BrowserRouter>
  </>
  // </React.StrictMode>,
);
