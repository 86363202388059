import {object, ref, string} from "yup";
import {IRegistryProfileInitialValues} from "../../../../types/registry";

export default object<IRegistryProfileInitialValues>().shape({
	newPassword: string()
		.trim()
		.required("ERRORS.FORM.REQUIRED")
		.min(8, "ERRORS.FORM.SHOULD_BE_AT_LEAST_8_CHARACTERS")
		.max(16, "ERRORS.FORM.SHOULD_BE_AT_MOST_16_CHARACTERS")
		.minLowercase(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT_SYMBOL")
		.minUppercase(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT_SYMBOL")
		.minNumbers(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT_SYMBOL")
		.minSymbols(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT_SYMBOL"),
	confirmPassword: string()
		.oneOf([ref("newPassword")], "ERRORS.FORM.INVALID_PASSWORD_MATCH")
		.required("ERRORS.FORM.REQUIRED"),
});
