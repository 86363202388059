import React, {useEffect, useState} from "react";
import {
    Alert,
    Box,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import {t} from "i18next";
import BasicButton from "../../../../compontensNuovi/buttons/BasicButton";
import {IAlert} from "../../../../types/commons";
import {EContactType, IOperator, IRegistry} from "../../../../types/registry";
import {RegistryApiService} from "../../../../services/RegistryApiService";
import {TableOperatorsProps} from "../../../../type";

const TableOperators: React.FC<TableOperatorsProps> = ({reloadTable , isDetailOfCompany = false, tenant }) => {
	const [admin, setAdmin] = useState<Partial<IRegistry>>();
	const [operators, setOperators] = useState<IOperator[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [reload, setReload] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		severity: "error",
		message: "",
		dismissible: true,
		onClose: () => {
			setAlert({...alert, show: false});
		},
	});
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [selectedOperatorID, setSelectedOperatorID] = useState<string | null>(null);

	const registryApiService = new RegistryApiService();

	const skeletonComponent = () => {
		return (
			<Box marginY={1}>
				{[4, 2, 8, 7, 3, 10, 7, 8, 9, 4].map((item, key) => (
					<Skeleton key={key} height={30} width={item * 10 + "%"} />
				))}
			</Box>
		);
	};

	const handleOpenDialog = (id: string) => {
		setSelectedOperatorID(id);
		setAlert({
			...alert,
			show: false,
		});
		setOpenDialog(true);
	};

	const handleCloseDialog = (): void => {
		setAlert({
			...alert,
			show: false,
		});
		setOpenDialog(false);
	};

	const deleteRegistry = () => {
		if (openDialog && selectedOperatorID != null) {
			registryApiService
				.deleteRegistry(selectedOperatorID)
				.then((response) => {
					setOpenDialog(false);
					setReload(!reload);
					setAlert({
						...alert,
						show: false,
					});
				})
				.catch((error) => {
					setAlert({
						...alert,
						show: true,
						message: error || t("ERRORS.TABLE_OPERATOR.DELETE_ERROR"),
					});
				});
		}
	};

	useEffect(() => {
		registryApiService.infoMe().then((response) => {
			setAdmin(response);
		});
	}, []);

	useEffect(() => {
		if (admin) {
			setIsLoading(true);

			if (isDetailOfCompany && tenant) {
				registryApiService
					.getDipendentiForCompany(tenant)
					.then((response) => {
						const adminEmail = admin.id_contact?.find((contact) => contact.id_contact_type?.code === EContactType.EMAIL)?.contact;
						const filteredOperators = response.filter((operator) => {
							const operatorEmail = operator.id_contact.find((contact) => contact.id_contact_type?.code === EContactType.EMAIL)?.contact;
							return operatorEmail !== adminEmail;
						});
						setOperators(filteredOperators);
					})
					.catch((error) => {
						console.error(error);
					})
					.finally(() => {
						setIsLoading(false);
					});
			} else {
				registryApiService
					.getDipendenti()
					.then((response) => {
						const adminEmail = admin.id_contact?.find((contact) => contact.id_contact_type?.code === EContactType.EMAIL)?.contact;
						const filteredOperators = response.filter((operator) => {
							const operatorEmail = operator.id_contact.find((contact) => contact.id_contact_type?.code === EContactType.EMAIL)?.contact;
							return operatorEmail !== adminEmail;
						});
						setOperators(filteredOperators);
					})
					.catch((error) => {
						console.error(error);
					})
					.finally(() => {
						setIsLoading(false);
					});
			}
		}
	}, [admin, reload, reloadTable]);

	const confirmText = t("CONFIRM");
	const cancelText = t("CANCEL");

	let confirmDeleteDialog = (
		<Dialog open={openDialog} onClose={handleCloseDialog}>
			<div style={{backgroundColor: " var(--grey-200)", padding: "15px"}}>
				<DialogTitle sx={{color: "black", fontWeight: 700}}>{t("SETTINGS.TAB_USERS.USER_TABLE.DIALOG.TITLE")}</DialogTitle>
				<DialogContent>
					{alert.show && (
						<Alert className="mb-3" severity={alert.severity} onClose={alert.onClose}>
							{t(alert.message)}
						</Alert>
					)}
				</DialogContent>
				<DialogActions>
					<BasicButton onClick={deleteRegistry} text={confirmText} />
					<BasicButton
						onClick={handleCloseDialog}
						text={cancelText}
						maxWidth="fit-content"
						type="button"
						backgroundColor="trasparent"
						backgroundColorHover="var( --grey-500)"
						borderColorHover="var( --grey-500)"
						borderColor="black"
					/>
				</DialogActions>
			</div>
		</Dialog>
	);

	let tableBody;

	if (isLoading) {
		tableBody = (
			<TableRow>
				<TableCell colSpan={5}>{t("SETTINGS.TAB_USERS.USER_TABLE.LOADING")}</TableCell>
			</TableRow>
		);
	} else if (operators.length === 0) {
		tableBody = (
			<TableRow>
				<TableCell colSpan={5}>{t("SETTINGS.TAB_USERS.USER_TABLE.NO_DATA")}</TableCell>
			</TableRow>
		);
	} else {
		tableBody = (
			<>
				{operators.map((operator, index) => {
					const email = operator.id_contact.find((contact) => contact.id_contact_type?.code === EContactType.EMAIL)?.contact ?? "Nessun email";
					const phone = operator.id_contact.find((contact) => contact.id_contact_type?.code === EContactType.PHONE)?.contact ?? "Nessun telefono";

					const actionButton = (
						<IconButton
							onClick={() => {
								if (operator._id != null) {
									handleOpenDialog(operator._id);
								}
							}}
							disabled={isDetailOfCompany}
						>
							<CloseIcon />
						</IconButton>
					);

					return (
						<TableRow key={index}>
							<TableCell sx={{textTransform: "capitalize"}}>{operator.firstname}</TableCell>
							<TableCell sx={{textTransform: "capitalize"}}>{operator.surname}</TableCell>
							<TableCell>{email}</TableCell>
							<TableCell>{phone}</TableCell>
							<TableCell>{actionButton}</TableCell>
						</TableRow>
					);
				})}
			</>
		);
	}

	return (
		<Card variant="outlined" sx={{marginBottom: "20px"}}>
			<CardHeader title={t("SETTINGS.TAB_USERS.USER_TABLE.TITLE")}></CardHeader>
			<CardContent>
				{isLoading ? (
					skeletonComponent()
				) : (
					<div>
						{confirmDeleteDialog}
						<TableContainer component={Paper} variant="outlined">
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>{t("NAME")}</TableCell>
										<TableCell>{t("SURNAME")}</TableCell>
										<TableCell>{t("EMAIL")}</TableCell>
										<TableCell>{t("PHONE")}</TableCell>
										<TableCell>{t("DELETE")}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>{tableBody}</TableBody>
							</Table>
						</TableContainer>
					</div>
				)}
			</CardContent>
		</Card>
	);
};

export default TableOperators;
