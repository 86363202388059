import {InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, {useState} from "react";

type SearchBarProps = {
  onSearch: (searchTerm: string) => void;
  placeholder: string;
};

const SearchBar: React.FC<SearchBarProps> = ({ onSearch, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <>
      <TextField
        placeholder={placeholder}
        id="search-bar-input" // Utilizziamo un ID univoco per l'input
        variant="outlined"
        sx={{
          width: "33rem",
          marginBottom: "1em",
          backgroundColor: "var(--white)",
          margin: "0",
          "& input::placeholder": {
            fontSize: "1rem",
          },
          "& input": {
            fontSize: "1rem",
            height: "3rem",
            padding: "0",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={searchTerm}
        onChange={handleChange}
        autoComplete="off" // Assicurati che sia anche qui
        name="search-bar-input" // Assicurati che sia anche qui
      />
    </>
  );
};

export default SearchBar;
