import React, {FC} from "react";

interface StatusProps {
	displayText?: string;
	stato: string;
	transitionCode?: string; // Aggiunto prop opzionale per il codice di transizione
	isClickable?: boolean;
	onClick?: () => void;
}

const STATUS_STYLES: {[key: string]: React.CSSProperties} = {
	NUOVA: {
		backgroundColor: "var(--newStatus)",
		color: "white",
	},
	"IN CARICO": {
		backgroundColor: "var(--inProgressStatus)",
		color: "black",
	},
	CHIUSA: {
		backgroundColor: "var(--doneStatus)",
		color: "white",
	},
};

const defaultStyle: React.CSSProperties = {
	borderRadius: "50px",
	width: "13vw",
	minWidth: "130px",
	maxWidth: "200px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	fontSize: "1rem",
	fontWeight: "700",
	height: "25px",
	padding: "0 15px",
};

const unknownStyle: React.CSSProperties = {
	backgroundColor: "var(--unknownStatus)",
	color: "white",
};

const StatusHighlight: FC<StatusProps> = ({displayText, stato, transitionCode, isClickable = false, onClick}) => {
	let style;
	if (transitionCode && STATUS_STYLES[transitionCode]) {
		// Applica lo stile basato sul codice di transizione, se disponibile
		style = {...defaultStyle, ...STATUS_STYLES[transitionCode]};
	} else {
		// Altrimenti, usa lo stile basato sullo stato come fallback
		style = STATUS_STYLES[stato] ? {...defaultStyle, ...STATUS_STYLES[stato]} : {...defaultStyle, ...unknownStyle};
	}

	if (isClickable) {
		style.cursor = "pointer";
		style.boxShadow = "0px 15px 10px -7px rgba(0,0,0,0.3)";
	}

	const textToDisplay = displayText || stato;

	return (
		<div style={style} onClick={isClickable ? onClick : undefined}>
			{textToDisplay}
		</div>
	);
};

export default StatusHighlight;
