import React, {ChangeEvent, useEffect, useState} from "react";
import {Grid, MenuItem, Select, TextField} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import styles from "./AddUtenteNew.module.css";
import {codiceFiscaleRegex, emailRegex, phoneRegex} from "../../utils/regex";
import {toast_error, toast_success} from "../../utils/custom-toast";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Employee, Sede} from "../../type";
import SaveButton from "../../componets/Buttons/SaveButton";
import {Container} from "react-bootstrap";
import Topbar from "../Topbar/Topbar";
import {API_POST_ADD_UTENTE_AZIENDA} from "../../api/api";
import BackButton from "../../componets/Buttons/BackButton";
import Footer from "../../componets/Footer/Footer";
import {RegistryApiService} from "../../services/RegistryApiService";
import {EContactType, ERegistryType, IRegistry} from "../../types/registry";
import {EAuthRole, EAuthSessionData} from "../../types/auth";
import {useTranslation} from "react-i18next";

const AddUtenteNew: React.FC = () => {
    const navigate = useNavigate();
    const {aziendaId} = useParams();
    const initialEmployeeState: Employee = {
        firstname: "",
        surname: "",
        profession: "",
        email: "",
        phoneNumber: "",
        fiscaleCode: "",
        sede: "",
        azienda: "",
        facebook: "",
        linkedin: "",
        instagram: "",
        twitter: "",
    };
    const [employee, setEmployee] = useState<Employee>(initialEmployeeState);
    const [azienda, setAzienda] = useState<{ _id: string; business_name: string }[]>([]);
    const [idSelectedAzienda, setIdSelectedAzienda] = useState<string>(aziendaId || ''); // Inizializza con aziendaId o con un altro valore di default se necessario
    const [registryIdCodeDipendente, setRegistryIdCodeDipendente] = useState<string>("");
    const [contactIdEmailCode, setContactIdEmailCode] = useState<string>("");
    const [contactIdPhoneCode, setContactIdPhoneCode] = useState<string>("");
    const [contactIdFacebookCode, setContactIdFacebook] = useState<string>("");
    const [contactIdInstagramCode, setContactIdInstagram] = useState<string>("");
    const [contactIdTwitterCode, setContactIdTwitter] = useState<string>("");
    const [contactIdLinkedinCode, setContactIdLinkedin] = useState<string>("");
    const {t} = useTranslation();

    const registryApiService = new RegistryApiService();

    // TODO: Usare singola chiamata per recuperara e assegnare i vari id per i tipi di contatto, basterebbe una search e un filtro sul uso risultato
    useEffect(() => {
        const loadData = async () => {
            const registryIdDipendente: any = await registryApiService.getRegistryIdByType(ERegistryType.DIPENDENTE);
            setRegistryIdCodeDipendente(registryIdDipendente);

            const registryIdAzienda: any = await registryApiService.getRegistryIdByType(ERegistryType.AZIENDA);

            const aziendaGet: any[] | null = await registryApiService.fetchAllRegistryByType(ERegistryType.AZIENDA);

			const aziendaFormatted = aziendaGet.map((item: Sede) => ({
				_id: item._id,
				business_name: item.business_name,
			}));

            setAzienda(aziendaFormatted);
            const contactIdEmail: any = await registryApiService.getContactIdByType(EContactType.EMAIL);
            setContactIdEmailCode(contactIdEmail);

            const contactIdPhone: any = await registryApiService.getContactIdByType(EContactType.PHONE);
            setContactIdPhoneCode(contactIdPhone);

            const contactIdFacebook: any = await registryApiService.getContactIdByType(EContactType.FACEBOOK);
            setContactIdFacebook(contactIdFacebook);

            const contactIdInstagram: any = await registryApiService.getContactIdByType(EContactType.INSTAGRAM);
            setContactIdInstagram(contactIdInstagram);

            const contactIdTwitter: any = await registryApiService.getContactIdByType(EContactType.TWITTER);
            setContactIdTwitter(contactIdTwitter);

            const contactIdLinkedin: any = await registryApiService.getContactIdByType(EContactType.LINKEDIN);
            setContactIdLinkedin(contactIdLinkedin);
        };
        loadData();
    }, []);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const {name, value} = event.target;
		setEmployee((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleBackClick = () => {
		navigate(`/Homepage`);
	};
	const aToken = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN);

    //add try catch
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const requestBody = {
            id_registry_owner: idSelectedAzienda,
            registry_type: ERegistryType.DIPENDENTE,
            firstname: employee.firstname,
            surname: employee.surname,
            registry_address: [],
            fiscal_code: employee.fiscaleCode,
            profession: employee.profession,
            isManual: true,
            user: {
                email: employee.email,
                applicationsEnabled: ["wb"],
                role: EAuthRole.ADMIN,
            },
            contact: [
                {
                    contact_type: EContactType.EMAIL,
                    contact: employee.email,
                },
                {
                    contact_type: EContactType.PHONE,
                    contact: employee.phoneNumber,
                },

                employee.facebook && {
                    contact_type: EContactType.FACEBOOK,
                    contact: employee.facebook,
                },
                employee.instagram && {
                    contact_type: EContactType.INSTAGRAM,
                    contact: employee.instagram,
                },
                employee.twitter && {
                    contact_type: EContactType.TWITTER,
                    contact: employee.twitter,
                },
                employee.linkedin && {
                    contact_type: EContactType.LINKEDIN,
                    contact: employee.linkedin,
                },
            ].filter(Boolean),
        };
        // Validation
        let hasError = false;

		if (!employee.firstname) {
			toast_error("Nome obbligatorio.");
			hasError = true;
		}

		if (!employee.surname) {
			toast_error("Cognome obbligatorio.");
			hasError = true;
		}

		if (!employee.profession) {
			toast_error("Ruolo obbligatorio.");
			hasError = true;
		}

		if (!idSelectedAzienda) {
			toast_error("Azienda obbligatoria.");
			hasError = true;
		}

		if (!employee.email) {
			toast_error("Email obbligatoria.");
			hasError = true;
		} else if (!emailRegex.test(employee.email)) {
			toast_error("Email non valida.");
			hasError = true;
		}

		if (!employee.phoneNumber) {
			toast_error("Telefono obbligatorio.");
			hasError = true;
		} else if (!phoneRegex.test(employee.phoneNumber)) {
			toast_error("Numero di telefono non valido.");
			hasError = true;
		}

		if (!employee.fiscaleCode) {
			toast_error("Codice Fiscale obbligatorio.");
			hasError = true;
		} else if (!codiceFiscaleRegex.test(employee.fiscaleCode)) {
			toast_error("Codice Fiscale non valido.");
			hasError = true;
		}

		if (hasError) {
			return;
		}

     

        registryApiService.createRegistry(requestBody)
            .then((response) => {
                if (response) {
                    setEmployee(initialEmployeeState);
                    setIdSelectedAzienda("");
                    toast_success(t("OPERATION_SUCCESS"));
                }
            })
            .catch((error) => {
                toast_error(error);
            });
    };

	const emailValidation = emailRegex.test(employee.email);
	const isPhoneValid = phoneRegex.test(employee.phoneNumber);
	const isCodiceFiscaleValid = codiceFiscaleRegex.test(employee.fiscaleCode);
	return (
		<>
			<Topbar />
			<Container>
				<ToastContainer position="top-right" autoClose={3000} />
				<div className={styles.container}>
					<h1>Nuovo Dipendente</h1>
					<form onSubmit={handleSubmit}>
						<Grid item xs={12} sm={9}>
							{/* prima griglia */}
							<Grid container spacing={2} sx={{marginTop: 1}}>
								<Grid item xs={12} sm={12}>
									<div className={styles.title}>DATI PERSONALI</div>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										label="Nome"
										variant="outlined"
										fullWidth
										name="firstname"
										value={employee.firstname}
										onChange={handleChange}
										error={!employee.firstname}
										required
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										label="Cognome"
										variant="outlined"
										fullWidth
										name="surname"
										value={employee.surname}
										onChange={handleChange}
										error={!employee.surname}
										required
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										label="Ruolo"
										variant="outlined"
										fullWidth
										name="profession"
										value={employee.profession}
										onChange={handleChange}
										error={!employee.profession}
										required
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Select
										variant="outlined"
										fullWidth
										name="azienda"
										value={idSelectedAzienda}
										onChange={(event) => setIdSelectedAzienda(event.target.value as string)}
										required
									>
										{azienda.map((aziendaItem) => {
											if (aziendaItem._id === aziendaId) {
												return (
													<MenuItem key={aziendaItem._id} value={aziendaItem._id}>
														{aziendaItem.business_name}
													</MenuItem>
												);
											}
											return null;
										})}
									</Select>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										label={!employee.email ? "Email" : !emailValidation ? "Email non valida" : "Email"}
										variant="outlined"
										fullWidth
										name="email"
										value={employee.email}
										onChange={handleChange}
										error={!employee.email || !emailValidation}
										required
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										label={!employee.phoneNumber ? "Telefono" : !isPhoneValid ? "Telefono non valido" : "Telefono"}
										variant="outlined"
										fullWidth
										name="phoneNumber"
										value={employee.phoneNumber}
										error={!employee.phoneNumber || !isPhoneValid}
										onChange={handleChange}
										required
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										label={!employee.fiscaleCode ? "Codice Fiscale" : !isCodiceFiscaleValid ? "Codice Fiscale non valido" : "Codice Fiscale"}
										variant="outlined"
										fullWidth
										name="fiscaleCode"
										error={!employee.fiscaleCode || !isCodiceFiscaleValid}
										value={employee.fiscaleCode.toUpperCase()}
										onChange={handleChange}
										required
									/>
								</Grid>
							</Grid>
							{/* seconda griglia SOCIAL + SAVE BUTTON*/}
							<Grid container spacing={2} sx={{marginTop: 1}}>
								<Grid item xs={12} sm={12}>
									<div className={styles.title}>SOCIAL NETWORK</div>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField label="Facebook" variant="outlined" fullWidth name="facebook" onChange={handleChange} value={employee.facebook} />
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField label="Instagram" variant="outlined" fullWidth name="instagram" onChange={handleChange} value={employee.instagram} />
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField label="Twitter" variant="outlined" fullWidth name="twitter" onChange={handleChange} value={employee.twitter} />
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField label="LinkedIn" variant="outlined" fullWidth name="linkedin" onChange={handleChange} value={employee.linkedin} />
								</Grid>
								<Grid item xs={1} sm={12} sx={{display: "flex", justifyContent: "flex-end"}}>
									<BackButton onClick={handleBackClick} />
									<Grid item xs={1} sm={12} sx={{display: "flex", justifyContent: "flex-end"}}>
										<SaveButton onClick={() => handleSubmit} />
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</div>
			</Container>
			<Footer />
		</>
	);
};

export default AddUtenteNew;
