import React from "react";
import {Navigate, Outlet} from "react-router-dom";

/**
 * Private route
 */
export default function PrivateRoute({evaluationFn, fallbackRoute}: PrivateRouteProps): React.JSX.Element {
    return evaluationFn() ? <Outlet/> : <Navigate to={fallbackRoute} replace/>;
}

interface PrivateRouteProps {
    evaluationFn: () => boolean;
    fallbackRoute: string;
}

