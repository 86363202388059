import React, {useState} from "react";
import TabMenu from "../../componets/tabMenu/TabMenu";
import {t} from "i18next";
import isSuperAdminGuard from "../../guards/isSuperAdminGuard";
import isPlatformAdminGuard from "../../guards/isPlatformAdminGuard";
import HomeListaAziende from "../HomeListaAziende/HomeListaAziende";
import Topbar from "../Topbar/Topbar";
import {Box, Grid} from "@mui/material";
import Footer from "../../componets/Footer/Footer";
import ListaOrganizzazioni from "../ListaOrganizzazioni/ListaOrganizzazioni";
import {ToastContainer} from "react-toastify";
import { useSelector } from "react-redux";

const Homepage: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const role = useSelector((state: any) => state.user.role);
    const isSuperAdmin = isSuperAdminGuard(role);
    const isPlatformAdmin = isPlatformAdminGuard(role);

    const tabs = [
        { value: 0, label: t("COMPANY_TABLE.TITLE") },
        { value: 1, label: t("ORGANIZATION_TABLE.TITLE") },
    ];


    const handleTabChange = (newValue: number) => {
        setSelectedTab(newValue);
    };

    return (<>
        <ToastContainer />
            <Grid sx={{mt: 1}}>
                <Topbar />
            </Grid>
            { isPlatformAdmin && (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={10} marginTop={4}>
                            <Box display={"flex"} justifyContent={"flex-start"} marginLeft={"3%"}>
                                <TabMenu value={selectedTab} onChange={handleTabChange} tabs={tabs}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            { selectedTab === 0 && (
                                <HomeListaAziende />
                            )}
                            { selectedTab === 1 && (
                                <ListaOrganizzazioni />
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
            { isSuperAdmin && (
                <HomeListaAziende />
            )}
            <Footer/>
        </>);
}
export default Homepage;
