import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {IPaymentTransaction} from "../../../../../types/payment";
import {PaymentApiService} from "../../../../../services/PaymentApiService";
import {formatStripePrice} from "../../../../../utils/helperFunctions";

const HistoryCard = () => {
    const {t} = useTranslation();
    const [transactions, setTransactions] = useState<IPaymentTransaction[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const paymentService = new PaymentApiService()

    const skeletonComponent = () => {
        return (
            <Box marginY={1}>
                {[4, 2, 8, 7, 3, 10, 7, 8, 9, 4].map((item, key) => (
                    <Skeleton key={key} height={30} width={item * 10 + '%'}/>
                ))}
            </Box>
        )
    }

    useEffect(() => {
        paymentService.paidTransactions().then((res) => {
            setTransactions(res);
            setLoading(false)
        }).catch(() => setLoading(false))
    }, [])
    return (
        <Card variant="outlined">
            <CardHeader title={t('SETTINGS.TAB_BILLING.CARD_HISTORY.TITLE')}></CardHeader>
            <CardContent>
                {loading && skeletonComponent()}
                {!loading && <TableContainer component={Paper} variant="outlined">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('SETTINGS.TAB_BILLING.CARD_HISTORY.DATE')}</TableCell>
                                <TableCell>{t('SETTINGS.TAB_BILLING.CARD_HISTORY.AMOUNT')}</TableCell>
                                <TableCell>{t('SETTINGS.TAB_BILLING.CARD_HISTORY.STATUS')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactions.map((row, key) => (
                                <TableRow
                                    key={key}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>
                                        {row.updatedAt}
                                    </TableCell>
                                    <TableCell>
                                        {formatStripePrice(row.plan.price)}
                                    </TableCell>
                                    <TableCell>
                                        <Chip size="small" label={t('SETTINGS.TAB_BILLING.CARD_HISTORY.PAID')}
                                              color="success"/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </CardContent>
        </Card>
    );
}

export default HistoryCard;
