import {ApiService} from "./ApiService";
import {WORKFLOW_URL} from "../api/api";
import {ICommonType, IListCount, TApiListSearchParam} from "../types/commons";
import {IStatusType} from "../types/workflow";

export class WorkflowApiService extends ApiService {
	constructor() {
		super(WORKFLOW_URL);
	}

	countWorkflowTypes(params?: TApiListSearchParam<IListCount>): Promise<IListCount> {
		return this.listCount<IListCount>("states", params);
	}

	getWorkflowTypes(params?: TApiListSearchParam<ICommonType>): Promise<ICommonType[]> {
		return this.listSearch<ICommonType>("states", params);
	}

	getTransitionRoles(params?: TApiListSearchParam<IStatusType>): Promise<IStatusType[]> {
		return this.listSearch<IStatusType>("transition-role", params);
	}

	stateTranslation<T>(params?: TApiListSearchParam<T>): Promise<any[]> {
		// const fullParams = {...params, type: "STATE"};
		return this.get<any[]>("/translations/list/search", {params: params});
	}
}
