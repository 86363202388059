import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import styles from "./TabMenu.module.css";
import {TabMenuProps} from "../../type";

const TabMenu: React.FC<TabMenuProps> = ({ value = 0, onChange, tabs }) => {

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onChange(newValue);
  };

  return (
    <Box className={styles.tabMenu}>
      <Tabs value={value} onChange={handleTabChange}>
        {tabs.map((tab) => (
          <Tab key={tab.value} value={tab.value} label={tab.label} />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabMenu;
