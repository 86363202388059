import {boolean, object, string} from "yup";
import {IBillingInfo} from "../../types/payment";
import {europeanVATRegex, intlPhoneRegex, sdiRegex} from "../../utils/regex";
import europeanCountryCodes from "../../utils/europeanCountryCodes";
import italianProvinces from "../../utils/italianProvinces";

export default object<IBillingInfo>().shape({
    country: string().trim().required("ERRORS.FORM.REQUIRED")
        .oneOf(europeanCountryCodes, "ERRORS.FORM.SELECT_ELEMENT_OF_LIST"),
    city: string().trim().required("ERRORS.FORM.REQUIRED").min(3).max(50),
    state: string().trim().required("ERRORS.FORM.REQUIRED")
        .oneOf([...italianProvinces, ...['EE']], "ERRORS.FORM.SELECT_ELEMENT_OF_LIST"),
    postal_code: string().trim().required("ERRORS.FORM.REQUIRED")
        .matches(/^\d{5}$/, "ERRORS.FORM.INVALID_POSTAL_CODE"),
    address: string().trim().required("ERRORS.FORM.REQUIRED").max(100),
    business_name: string().trim().required("ERRORS.FORM.REQUIRED").max(100),
    vat_number: string().trim().required("ERRORS.FORM.REQUIRED")
        .matches(europeanVATRegex, "ERRORS.FORM.INVALID_VAT_NUMBER"),
    pec: string().trim().when('country', {
            is: 'IT',
            then: (schema) => schema
                .required("ERRORS.FORM.REQUIRED")
                .email("ERRORS.FORM.INVALID_EMAIL"),
            otherwise: (schema) => schema
                .notRequired()
                .nullable()
        }
    ),
    billing_sdi: boolean().default(false),
    code_sdi: string().trim().when('billing_sdi', {
        is: true,
        then: (schema) => schema
            .required("ERRORS.FORM.REQUIRED")
            .matches(sdiRegex, "ERRORS.FORM.INVALID_SDI_CODE"),
        otherwise: (schema) => schema
            .notRequired()
            .nullable()
            .matches(sdiRegex, "ERRORS.FORM.INVALID_SDI_CODE")
    }),
    email: string().trim().required("ERRORS.FORM.REQUIRED").email("ERRORS.FORM.INVALID_EMAIL"),
    phone: string().trim().required("ERRORS.FORM.REQUIRED")
        .matches(intlPhoneRegex, "ERRORS.FORM.INVALID_PHONE"),
})
