import React, {useEffect} from "react";
import ProfiloCard from "../../ImpostazioniPages/tabs/ProfiloTab/components/ProfiloCard";
import {Grid} from "@mui/material";
import {useLocation} from "react-router-dom";
import {ProfiloProps} from "../../../type";

const ProfiloAzienda: React.FC<ProfiloProps> = ({ isDetailOfCompany, company }) => {

    // TODO: Profilo Azienda specifica
    return (
        <>
            {company && (
                <Grid container spacing={2} justifyContent={"center"}>
                    <Grid item xs={6}>
                        <ProfiloCard isDetailOfCompany={isDetailOfCompany} company={company} />
                    </Grid>
                </Grid>
            )}
        </>
    );
}
export default ProfiloAzienda;