import React, {useEffect, useState} from "react";
import {Box, Divider, Grid, IconButton, Stack, Tab, Tabs} from "@mui/material";
import {t} from "i18next";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {RegistryApiService} from "../../services/RegistryApiService";
import {toast_error} from "../../utils/custom-toast";
import {ToastContainer} from "react-toastify";
import {ERegistryType, IRegistry} from "../../types/registry";
import {Spinner} from "react-bootstrap";
import Topbar from "../Topbar/Topbar";
import styles from "../HomeListaAziende/HomeListaAziende.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Footer from "../../componets/Footer/Footer";
import TabMenu from "../../componets/tabMenu/TabMenu";
import ProfiloAzienda from "./Tabs/ProfiloAzienda";
import UtentiAzienda from "./Tabs/UtentiAzienda";

const DettaglioAzienda: React.FC = () => {
    const [ registries, setRegistries ] = useState<IRegistry[]>([]);
    const [ company, setCompany ] = useState<IRegistry>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const { companyId} = useParams<{companyId: string}>();
    const navigate = useNavigate();
    const registryApiService = new RegistryApiService();

    const handleTabChange = (newValue: number) => {
        setSelectedTab(newValue);
    }


    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (registries.length === 1) {
            setCompany(registries[0]);
        } else if (registries.length > 1) {
            filterRegistriesForCompany();
        }
    }, [registries]);

    const handleBackClick = () => {
        navigate(-1);
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const data = await registryApiService.getRegistryDetail(companyId ?? "");
            setRegistries(data);
        } catch (err: any) {
            console.error(err);
            toast_error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const filterRegistriesForCompany = () => {
        const companyFromRegistries: IRegistry = registries.filter(reg => reg.id_registry_type[0].code === ERegistryType.AZIENDA)[0];
        setCompany(companyFromRegistries);
    }

    const tabs = [
        { value: 0, label: t("TOPBAR.PROFILE") },
        { value: 1, label: t("TOPBAR.USERS") },
    ];
    return (
        <>
            <Grid sx={{mt: 1}}>
                <Topbar />
            </Grid>
            {isLoading && !company ? (<Spinner animation="border" />) : (
                <>
                    <div className={styles.labels}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Box>
                                    <Stack direction={"row"}>
                                        <IconButton onClick={handleBackClick} type="button" sx={{ mr: 1 }}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                        <div className={styles.subTitle}>{t("COMPANY_DETAIL.TITLE")}</div>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display={"flex"} justifyContent={"flex-start"} marginLeft={"1.5%"}>
                                    <TabMenu value={selectedTab} onChange={handleTabChange} tabs={tabs}/>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Box marginTop={3}>
                            { selectedTab === 0 ? (
                                <ProfiloAzienda isDetailOfCompany={true} company={company} />
                            ) : (
                                <UtentiAzienda tenant={company?.tenant ?? ""}/>
                            )}
                        </Box>
                    </div>
                </>
            )}
            <Footer/>
        </>
    );
}
export default DettaglioAzienda;