import {EContactType, IRegistry, IRegistryProfileInitialValues} from "../types/registry";
import {EAuthSessionData} from "../types/auth";

export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const daysAgo = (date: Date) => {
	const now = new Date();
	const then = new Date(date);
	const diffTime = Math.abs(now.getTime() - then.getTime());
	return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const formatDate = (data: any, includeTime: boolean = false) => {
	const date = new Date(data);
	const day = date.getDate().toString().padStart(2, "0");
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const year = date.getFullYear().toString();

	let formattedDate = `${day}/${month}/${year}`;

	if (includeTime) {
		const hours = date.getHours().toString().padStart(2, "0");
		const minutes = date.getMinutes().toString().padStart(2, "0");
		// const seconds = date.getSeconds().toString().padStart(2, "0");

		const formattedTime = `- ${hours}:${minutes}`;
		formattedDate += ` ${formattedTime}`;
	}

	return formattedDate;
};

export const generateColorMap = (data: {username: string}[]) => {
	let colorMap: Record<string, string> = {};
	data.forEach((item) => {
		if (!colorMap[item.username]) {
			colorMap[item.username] = "#" + Math.floor(Math.random() * 16777215).toString(16);
		}
	});
	return colorMap;
};

export const formatStripePrice = (price: string): string => {
	return (parseInt(price, 10) / 100).toFixed(2);
};

export const registryToProfileInitialValues = (registry: IRegistry): IRegistryProfileInitialValues => {
	// TODO: Transform registry to company object for profilo card
	let emailContact;
	let phoneContact;
	if (registry.id_contact) {
		emailContact = registry.id_contact.find((contact) => (contact.id_contact_type ? contact.id_contact_type.code === EContactType.EMAIL : false));
		phoneContact = registry.id_contact.find((contact) => (contact.id_contact_type ? contact.id_contact_type.code === EContactType.PHONE : false));
	}
	const tenant = registry.tenant;
	sessionStorage.setItem(EAuthSessionData.TENANT, tenant);

	const initialValues: IRegistryProfileInitialValues = {
		firstname: registry.firstname,
		surname: registry.surname,
		email: emailContact ? emailContact.contact : "",
		phone: phoneContact ? phoneContact.contact : "",
		business_name: registry.business_name,
	};

	return initialValues;
};

export const findLinkByTitle = (title: string, links: any) => {
	const linkObject = links.find((link: any) => link.title === title);
	return linkObject ? linkObject.link : "#";
};
